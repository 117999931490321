import React, {useState, useMemo, useEffect} from 'react';
import {Text, useWindowDimensions } from 'react-native';
import {Form, Button, Col, Row, Container, Image, InputGroup, ListGroup, ProgressBar} from 'react-bootstrap';
import u74 from './images/illu1.png';
import u75 from './images/logo_HealthSquad_complet.png';
import u76 from './images/illu2.png';
import u77 from './images/illu3.png';
import u78 from './images/illu4.png';
import u79 from './images/illu5.png';
import u80 from './images/illu6.png';
import u81 from './images/illu7.png';
import u82 from './images/illu8.png';
import u83 from './images/illu9.png';
import countryList from 'react-select-country-list';
import Modal3 from './components/Modal3'
import './style.css';
import axios from 'axios';
import {Translations} from '../src/constants/i18n';
import mailchimpFactory from '@mailchimp/mailchimp_transactional/src/index.js';
const mailchimp = mailchimpFactory("3MaihbLvVMRazJCeofwVyw");

const Inscription = ({translation}) => {

  const { height, scale, width } = useWindowDimensions();
  const {overwrite, getName } = require('country-list');
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(true);
  const color1 = "rgba(217, 228, 249, 1)";
  const color2 = "rgba(241, 246, 255, 1)";
  const [Nom, setNom] = useState('');
  const [Prenom, setPrenom] = useState('');
  const [Mail, setMail] = useState('');
  const [sexe, setSexe] = useState('');
  const [Profession, setProfession] = useState('');
  const [Metier, setMetier] = useState('');
  const [Special, setSpecial] = useState('');
  const [Exercice, setExercice] = useState(-1);
  const [phone, setPhone] = useState('');
  const [rue, setRue] = useState('');
  const [numRue, setnumRue] = useState('');
  const [compAd, setcompAd] = useState('');
  const [codepostale, setCodepostale] = useState('');
  const [ville, setVille] = useState('');
  const [value, setValue] = useState('FR')
  const [value2, setValue2] = useState('FR')
  const [Pays, setPays] = useState('France')
  const [national, setNational] = useState('France')
  const [check1, setcheck1] = useState(false);
  const [check2, setcheck2] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [BDate, setBDate] = useState('');
  const [disabledcolor, setdisabledcolor] = useState('#DCDCDC');
  const [disabled, setdisabled] = useState(false);
  const [disabledcolor1, setdisabledcolor1] = useState('#DCDCDC');
  const [disabled1, setdisabled1] = useState(false);
  const [disabledcolor2, setdisabledcolor2] = useState('#DCDCDC');
  const [disabled2, setdisabled2] = useState(false);
  const [disabledcolor3, setdisabledcolor3] = useState('#DCDCDC');
  const [disabled3, setdisabled3] = useState(false);
  const URL = 'https://www.healthsquad.fr/request/'
  const URL_ville =  "https://vicopo.selfbuild.fr/cherche/"
  const [dataVille, setDataVille] = useState([]);
  const [modalVisible3, setModalVisible3] = useState(false);
  
  const t = (value) => {
    return Translations[0][translation][value];
   }

  const optionSexe = [
    { value: '', label: 'Selectionner...' },
    { value: 1, label: 'Homme' },
    { value: 2, label: 'Femme' },
   // { value: 3, label: `Je m'identifie comme un homme` },
   // { value: 4, label: `Je m'identifie comme une femme` },
    { value: 5, label: `Je ne m'identifie à aucun genre` }
  ]

  const optionMetier = [
    { value: '', label: 'Selectionner...' },
    { value: 'Professionnel de santé', label: t("Signup17") },
    { value: 'Agriculteur', label: t("Signup18") },
    { value: `Artisan`, label: t("Signup19") },
    { value: `Commerçant`, label: t("Signup20") },
    { value: `Chef d'entreprise`, label: t("Signup21") },
    { value: `Cadre et profession Intellectuelle supérieure`, label: t("Signup22") },
    { value: `Professions Intermédiaires`, label: t("Signup23") },
    { value: `Employé`, label: t("Signup24") },
    { value: `Ouvrier`, label: t("Signup25") },
    { value: `Etudiant`, label: t("Signup26") },
    { value: `Inactif ou chômeur n'ayant jamais travaillé`, label: t("Signup27") },
  ]

  const options = useMemo(() => countryList().getData(), [])


  useEffect(()=>{
    window.scrollTo(0, 0)
    }, [])

  function validCommand() {
    //setShow(false);
    var position = Mail.indexOf("@");
var reste = Mail.substr(-position);
var posit = reste.indexOf(".");
//alert(posit)
if(position !== -1 && posit !== -1 ){
    if(disabled && disabled1){
      fetch(`${URL}json_get_react_find_mail_manu.php`, {
        method: 'post',
        headers: {
          'Accept': 'application/json',  // It can be used to overcome cors errors
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user_name:Mail   })
      })
        .then((response) => response.json())
        .then(response => { 
          console.log(response)
          if(response.length === 0 || response[0].Item_10 != 'HS'){
    setShow1(true)
    window.scrollTo(0, 0)}  else {
      alert(`l'adresse mail que vous avez choisi existe déjà`)
    }})
    }else{
      alert('Veuillez remplir tous les champs, Merci!')
    }
    console.log(Nom, Prenom, sexe, value, startDate)
  }else{
    alert('Email non valide')
  }
  }

  const ChangeNom = (event) => {
    setNom(event)
    if(event !== '' && Prenom !=='' && startDate !=='' && sexe !=='' && value !=='' && Mail !==''){
      setdisabledcolor('rgba(188, 78, 156, 1)')
      setdisabledcolor1('rgba(63, 120, 224, 1)')
      setdisabled(true)
      setdisabled1(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabledcolor1('#DCDCDC')
      setdisabled(false)
      setdisabled1(false)
    }
   console.log(event)
  };
  
  const ChangePrenom = (event) => {
    setPrenom(event)
    if(event !== '' && Nom !=='' && startDate !=='' && sexe !=='' && value !=='' && Mail !==''){
      setdisabledcolor('rgba(188, 78, 156, 1)')
      setdisabledcolor1('rgba(63, 120, 224, 1)')
      setdisabled(true)
      setdisabled1(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabledcolor1('#DCDCDC')
      setdisabled(false)
      setdisabled1(false)
    }
   console.log(event)
  };

  const ChangeMail = (event) => {
    setMail(event)
    if(event !== '' && Nom !==''  && Prenom !=='' && startDate !=='' && sexe !=='' && value !==''){
      setdisabledcolor('rgba(188, 78, 156, 1)')
      setdisabledcolor1('rgba(63, 120, 224, 1)')
      setdisabled(true)
      setdisabled1(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabledcolor1('#DCDCDC')
      setdisabled(false)
      setdisabled1(false)
    }
   console.log(event)
  };
  

  const ChangeGenre = (event) => {
    setSexe(event)
    if(event !== '' && Nom !=='' && startDate !=='' && Prenom !=='' && value !=='' && Mail !==''){
      setdisabledcolor('rgba(188, 78, 156, 1)')
      setdisabledcolor1('rgba(63, 120, 224, 1)')
      setdisabled(true)
      setdisabled1(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabledcolor1('#DCDCDC')
      setdisabled(false)
      setdisabled1(false)
    }
   console.log(event)
  };

  const changeHandler = value => {
    setValue(value)
    setNational(getName(value))
    if(value.value !== '' && Nom !=='' && startDate !=='' && Prenom !=='' && sexe !=='' && Mail !==''){
      setdisabledcolor('rgba(188, 78, 156, 1)')
      setdisabledcolor1('rgba(63, 120, 224, 1)')
      setdisabled(true)
      setdisabled1(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabledcolor1('#DCDCDC')
      setdisabled(false)
      setdisabled1(false)
    }
    //console.log(getName(value.value))
  }

  const handleChange = () => {
    setChecked(!checked);
        if(Profession === 'Professionnel de santé'){
        if(!checked === true && phone !=='' && Profession !=='' && Metier !=='' && Special !=='' && Exercice !== -1 && numRue !=='' && rue !=='' && codepostale !=='' && ville !=='' && Pays !==''){
          setdisabledcolor2('rgba(188, 78, 156, 1)')
          setdisabledcolor3('rgba(63, 120, 224, 1)')
          setdisabled2(true)
          setdisabled3(true)
          } else {
            setdisabledcolor2('#DCDCDC')
            setdisabledcolor3('#DCDCDC')
            setdisabled2(false)
            setdisabled3(false)
          }} else {
            if(!checked === true && phone !=='' && Profession !=='' && Metier !=='' && numRue !=='' && rue !=='' && codepostale !=='' && ville !=='' && Pays !==''){
              setdisabledcolor2('rgba(188, 78, 156, 1)')
      setdisabledcolor3('rgba(63, 120, 224, 1)')
      setdisabled2(true)
      setdisabled3(true)
              } else {
                setdisabledcolor2('#DCDCDC')
                setdisabledcolor3('#DCDCDC')
                setdisabled2(false)
                setdisabled3(false)
              }
            }
        console.log((!checked))
  };

  const handleChange2 = () => {
    setChecked2(!checked2);
    console.log(checked2)
  };

  const ChangeProfession = (event) => {
    setProfession(event)
    if(event === 'Professionnel de santé'){
      console.log('rr')
    if(event !== '' && phone !=='' && Metier !=='' && Special !=='' && Exercice !== -1 && numRue !=='' && rue !==''&& codepostale !=='' && ville !=='' && Pays !==''  && checked === true ){
      setdisabledcolor2('rgba(188, 78, 156, 1)')
      setdisabledcolor3('rgba(63, 120, 224, 1)')
      setdisabled2(true)
      setdisabled3(true)
    } else {
      setdisabledcolor2('#DCDCDC')
    setdisabledcolor3('#DCDCDC')
    setdisabled2(false)
    setdisabled3(false)
    }
  }else {
    if(event !== '' && phone !=='' && Metier !=='' && numRue !=='' && rue !=='' && codepostale !=='' && ville !=='' && Pays !==''  && checked === true ){
      setdisabledcolor2('rgba(188, 78, 156, 1)')
      setdisabledcolor3('rgba(63, 120, 224, 1)')
      setdisabled2(true)
      setdisabled3(true)
    } else {
      setdisabledcolor2('#DCDCDC')
    setdisabledcolor3('#DCDCDC')
    setdisabled2(false)
    setdisabled3(false)
    }


  }
   console.log(event)
  };

  const Changecheck1 = (event) => {
    setcheck1(!check1)
    const Exercice = event && check2  ? 3 : event ? 1 : check2 ? 2 : -1;
    setExercice(Exercice)
    if(Profession === 'Professionnel de santé'){
      if((event || check2)  && phone !=='' && Profession !=='' && Metier !=='' && Special !=='' && codepostale !=='' && rue !=='' && ville !=='' && Pays !=='' && numRue !=='' && checked === true){
        setdisabledcolor2('rgba(188, 78, 156, 1)')
        setdisabledcolor3('rgba(63, 120, 224, 1)')
        setdisabled2(true)
        setdisabled3(true)
  } else {
    setdisabledcolor2('#DCDCDC')
    setdisabledcolor3('#DCDCDC')
    setdisabled2(false)
    setdisabled3(false)
  }
}
console.log(Exercice)
};



const Changecheck2 = (event) => {
  setcheck2(!check2)
  const Exercice = check1 && event  ? 3 : check1 ? 1 : event ? 2 : -1;
  setExercice(Exercice)
  if(Profession === 'Professionnel de santé'){
  if((event || check1) && phone !=='' && Profession !=='' && Metier !=='' && Special !=='' && codepostale !=='' && rue !=='' && ville !=='' && Pays !=='' && numRue !=='' && checked === true){
    setdisabledcolor2('rgba(188, 78, 156, 1)')
        setdisabledcolor3('rgba(63, 120, 224, 1)')
        setdisabled2(true)
        setdisabled3(true)
  } else {
    setdisabledcolor2('#DCDCDC')
    setdisabledcolor3('#DCDCDC')
    setdisabled2(false)
    setdisabled3(false)
  }
}
console.log(Exercice)
};

const ChangeDate = (event) => {
  ///const moonLanding = new Date(event);
  setStartDate(event)
  setBDate(event)

  if(event !== '' && Nom !=='' && sexe !=='' && Prenom !=='' && value !=='' && Mail !==''){
    setdisabledcolor('rgba(188, 78, 156, 1)')
      setdisabledcolor1('rgba(63, 120, 224, 1)')
      setdisabled(true)
      setdisabled1(true)
    } else {
      setdisabledcolor('#DCDCDC')
      setdisabledcolor1('#DCDCDC')
      setdisabled(false)
      setdisabled1(false)
  }
 console.log(event)
};

const ChangePhone = (event) => {
  setPhone(event)
  if(Profession === 'Professionnel de santé'){
    if(event !== '' && Profession !=='' && Metier !=='' && Special !=='' && Exercice !== -1 && numRue !=='' && rue !==''&& codepostale !==''  && ville !=='' && Pays !==''  && checked === true){
      setdisabledcolor2('rgba(188, 78, 156, 1)')
      setdisabledcolor3('rgba(63, 120, 224, 1)')
      setdisabled2(true)
      setdisabled3(true)
  } else {
    setdisabledcolor2('#DCDCDC')
    setdisabledcolor3('#DCDCDC')
    setdisabled2(false)
    setdisabled3(false)
  }
  }else {
    if(event !== '' && Profession !=='' && Metier !=='' && numRue !=='' && rue !==''&& codepostale !==''  && ville !=='' && Pays !==''  && checked === true){
      setdisabledcolor2('rgba(188, 78, 156, 1)')
      setdisabledcolor3('rgba(63, 120, 224, 1)')
      setdisabled2(true)
      setdisabled3(true)
    } else {
      setdisabledcolor2('#DCDCDC')
    setdisabledcolor3('#DCDCDC')
    setdisabled2(false)
    setdisabled3(false)
    }
  }
 
 console.log(event)
};

const ChangeMetier = (event) => {
  setMetier(event)
  if(Profession === 'Professionnel de santé'){
  if(event !== '' && phone !=='' && Profession !=='' && Special !=='' && Exercice !== -1 && codepostale !=='' && rue !=='' && ville !=='' && Pays !=='' && numRue !=='' && checked === true){
    setdisabledcolor2('rgba(188, 78, 156, 1)')
    setdisabledcolor3('rgba(63, 120, 224, 1)')
    setdisabled2(true)
    setdisabled3(true)
  } else {
    setdisabledcolor2('#DCDCDC')
    setdisabledcolor3('#DCDCDC')
    setdisabled2(false)
    setdisabled3(false)
  } }else {
    if(event !== '' && phone !=='' && Profession !=='' && codepostale !=='' && rue !=='' && ville !=='' && Pays !=='' && numRue !=='' && checked === true){
      setdisabledcolor2('rgba(188, 78, 156, 1)')
      setdisabledcolor3('rgba(63, 120, 224, 1)')
      setdisabled2(true)
      setdisabled3(true)
    } else {
      setdisabledcolor2('#DCDCDC')
      setdisabledcolor3('#DCDCDC')
      setdisabled2(false)
      setdisabled3(false)
    }
  }
 console.log(event)
};

const ChangeSpecial = (event) => {
  setSpecial(event)
  if(Profession === 'Professionnel de santé'){
    if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && Exercice !== -1 && codepostale !=='' && rue !=='' && ville !=='' && Pays !=='' && numRue !=='' && checked === true){
      setdisabledcolor2('rgba(188, 78, 156, 1)')
      setdisabledcolor3('rgba(63, 120, 224, 1)')
      setdisabled2(true)
      setdisabled3(true)
    } else {
      setdisabledcolor2('#DCDCDC')
      setdisabledcolor3('#DCDCDC')
      setdisabled2(false)
      setdisabled3(false)
    }
  }
 console.log(event)
};

const ChangenumRue = (event) => {
  setnumRue(event)
  if(Profession === 'Professionnel de santé'){
  if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && Special !=='' && Exercice !== -1 && rue !=='' && codepostale !=='' && ville !=='' && Pays !=='' && checked === true){
    setdisabledcolor2('rgba(188, 78, 156, 1)')
    setdisabledcolor3('rgba(63, 120, 224, 1)')
    setdisabled2(true)
    setdisabled3(true)
  } else {
    setdisabledcolor2('#DCDCDC')
      setdisabledcolor3('#DCDCDC')
      setdisabled2(false)
      setdisabled3(false)
  } } else {
    if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && rue !=='' && codepostale !=='' && ville !=='' && Pays !=='' && checked === true){
    setdisabledcolor2('rgba(188, 78, 156, 1)')
    setdisabledcolor3('rgba(63, 120, 224, 1)')
    setdisabled2(true)
    setdisabled3(true)
    } else {
      setdisabledcolor2('#DCDCDC')
      setdisabledcolor3('#DCDCDC')
      setdisabled2(false)
      setdisabled3(false)
    }
  }
 console.log(event)
};

const ChangeRue = (event) => {
  setRue(event)
  if(Profession === 'Professionnel de santé'){
  if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && Special !=='' && Exercice !== -1 && numRue !=='' && codepostale !=='' && ville !=='' && Pays !==''  && checked === true){
    setdisabledcolor2('rgba(188, 78, 156, 1)')
    setdisabledcolor3('rgba(63, 120, 224, 1)')
    setdisabled2(true)
    setdisabled3(true)
  } else {
    setdisabledcolor2('#DCDCDC')
      setdisabledcolor3('#DCDCDC')
      setdisabled2(false)
      setdisabled3(false)
  } } else {
    if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && numRue !=='' && codepostale !=='' && ville !=='' && Pays !==''  && checked === true){
      setdisabledcolor2('rgba(188, 78, 156, 1)')
      setdisabledcolor3('rgba(63, 120, 224, 1)')
      setdisabled2(true)
      setdisabled3(true)
    } else {
      setdisabledcolor2('#DCDCDC')
      setdisabledcolor3('#DCDCDC')
      setdisabled2(false)
      setdisabled3(false)
    }
  }
 console.log(event)
};

const ChangeCompAd = (event) => {
  setcompAd(event)
 console.log(event)
};

const ChangeCodepostale = (event) => {
  dataVille.length = 0
  setModalVisible3(false)
  axios.get(`${URL_ville}${event}`).then(function(response){
      response.data.cities.map((item, index) => {
        dataVille.push({ key: index++,"label":item.city})
       // setData([...data,{ key: index++,"label":item.city}])
       console.log(dataVille)
      })
     
       })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(() =>  {
     

setCodepostale(event)
if(Profession === 'Professionnel de santé'){
if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && Special !=='' && Exercice !== -1 && numRue !=='' && rue !=='' && ville !=='' && Pays !==''  && checked === true){
  setdisabledcolor2('rgba(188, 78, 156, 1)')
      setdisabledcolor3('rgba(63, 120, 224, 1)')
      setdisabled2(true)
      setdisabled3(true)
} else {
  setdisabledcolor2('#DCDCDC')
      setdisabledcolor3('#DCDCDC')
      setdisabled2(false)
      setdisabled3(false)
}} else {
  if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && numRue !=='' && rue !=='' && ville !=='' && Pays !==''  && checked === true){
    setdisabledcolor2('rgba(188, 78, 156, 1)')
      setdisabledcolor3('rgba(63, 120, 224, 1)')
      setdisabled2(true)
      setdisabled3(true)
  } else {
    setdisabledcolor2('#DCDCDC')
      setdisabledcolor3('#DCDCDC')
      setdisabled2(false)
      setdisabled3(false)
  }
}
console.log(event)})
};


const ChangeVille = (event) => {
  setVille(event)
  if(Profession === 'Professionnel de santé'){
  if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && Special !=='' && Exercice !== -1 && numRue !=='' && rue !=='' && codepostale !=='' && Pays !==''  && checked === true){
    setdisabledcolor2('rgba(188, 78, 156, 1)')
    setdisabledcolor3('rgba(63, 120, 224, 1)')
    setdisabled2(true)
    setdisabled3(true)
  } else {
    setdisabledcolor2('#DCDCDC')
      setdisabledcolor3('#DCDCDC')
      setdisabled2(false)
      setdisabled3(false)
  }} else { if(event !== '' && phone !=='' && Profession !=='' && Metier !=='' && numRue !=='' && rue !=='' && codepostale !=='' && Pays !==''  && checked === true){
    setdisabledcolor2('rgba(188, 78, 156, 1)')
    setdisabledcolor3('rgba(63, 120, 224, 1)')
    setdisabled2(true)
    setdisabled3(true)
  } else {
    setdisabledcolor2('#DCDCDC')
      setdisabledcolor3('#DCDCDC')
      setdisabled2(false)
      setdisabled3(false)
  }
}
 console.log(event)
};


const changeHandler2 = value => {
  //Pays
 setValue2(value)
 setPays(getName(value))
 if(Profession === 'Professionnel de santé'){
 if(value.value !== '' && phone !=='' && Profession !=='' && Metier !=='' && Special !=='' && Exercice !== -1 && rue !=='' && codepostale !=='' && ville !=='' && numRue !=='' && checked === true ){
  setdisabledcolor2('rgba(188, 78, 156, 1)')
  setdisabledcolor3('rgba(63, 120, 224, 1)')
  setdisabled2(true)
  setdisabled3(true)
 } else {
  setdisabledcolor2('#DCDCDC')
  setdisabledcolor3('#DCDCDC')
  setdisabled2(false)
  setdisabled3(false)
 }} else {
   if(value.value !== '' && phone !=='' && Profession !=='' && Metier !=='' && rue !=='' && codepostale !=='' && ville !=='' && numRue !=='' && checked === true ){
    setdisabledcolor2('rgba(188, 78, 156, 1)')
    setdisabledcolor3('rgba(63, 120, 224, 1)')
    setdisabled2(true)
    setdisabled3(true)
   } else {
    setdisabledcolor2('#DCDCDC')
    setdisabledcolor3('#DCDCDC')
    setdisabled2(false)
    setdisabled3(false)
   }
 }
//console.log(getName(value.value))
}

const run = async (message) => {
  const response = await mailchimp.messages.sendTemplate({
    template_name: "SendRegisterSiteHS",
    "template_content": [ {}],
    message: message,
  });
  console.log(response);
};

function validCommand1() {
  if(disabled2 && disabled3){
    //setShow(false);
    setShow1(false);
    
    fetch(`${URL}siteHS_json_input_register_site.php`, {
      method: 'post',
      headers: {
        'Accept': 'application/json',  // It can be used to overcome cors errors
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({name:Nom,
                            surname:Prenom,
                            genre:sexe,
                            pays:Pays,
                            bday:BDate,
                            mail:Mail,
                            country:value2,
                            phone:phone,
                            profession:Profession,
                            metier:Metier,
                            special:Special,
                            exercice:Exercice,
                            numrue:numRue,
                            rue:rue,
                            postal:codepostale,
                            ville:ville,
                            adressup:compAd,
                            national:national,
                            newsletter:checked2
                          })
    })
      .then((response) => response.json())
      .then(() => {
        const message = {
          from_name: "HealthSquad",
          from_email: "contact@healthsquad.fr",
          subject: `Confirmation de votre compte HealthSquad`,
          to: [
            {
              email: Mail,
              type: "to"
            }
          ],
          "merge_vars": [
            {
                "rcpt": Mail,
                "vars": [
                  {
                    "name": "ID1",
                    "content": `https://www.useradventure.net/siteHS_comfirmation_compte_site.php?Email=${Mail}`
                  },
                  {
                    "name": "SURNAME",
                    "content": Prenom
                  }
                ]
            }
        ]
        }
      run(message)
      setShow2(true)
      setNom('')
      setPrenom('')
      setMail('')
      setSexe('')
      setValue('FR')
      setStartDate('')
      setPhone('')
      setnumRue('')
      setRue('')
      setcompAd('')
      setCodepostale('')
      setVille('')
      setPays('')
      setProfession('')
      setMetier('')
      setSpecial('')
      setValue2('FR')
    })
      .catch((error) => console.error(error))
      
    }else{
      alert('Veuillez remplir tous les champs, Merci!')
    }
 
  console.log(Nom, Prenom, sexe, value, startDate, phone, Profession, Metier, Special, Exercice, numRue, rue, compAd, codepostale, ville, Pays, checked )
   window.scrollTo(0, 0)
}

function validCommand3() {
  document.location = "https://www.healthsquad.fr";
  //window.open('https://www.healthsquad.fr/')
}

  return (<>
    { !show1 ?  
      <Container fluid style={{background: `linear-gradient(to right,  ${color1} 60%,${color2} 90%)`, paddingBlock:40, margin:0}}>
     {/* <Col style={{background: 'white', padding:0, margin:0}}  className="text-center" >
     <Button variant="outline-light" style={{borderWidth:0}} onClick={() => {
              window.open('https://www.healthsquad.fr/', '_blank')}}>
     <Image src={u75} style={{height: width > 800 ? 100 : 70}}/>
              </Button>
     </Col> */}
     <Col style={{paddingTop:120}} lg={{ span: 4, offset: 4 }} xs={{ span: 6, offset: 3 }}>
     <ProgressBar animated now={49} style={{backgroundColor:'white'}}/>
     </Col>
     <Col md={{ span: 10, offset:1  }} lg={{ span: 10, offset: 1 }} style={{marginTop:20,background: 'rgba(255, 255, 255, 0.35)',borderRadius:15, padding:20,marginBlock:40}}>
    <Row style={{justifyContent:'center',alignItems:'center'}}>
     <Col   className="text-center d-none d-xl-block"  >
     <Image src={u74}  />
     <Col lg={{ span: 6, offset: 3 }} style={{paddingBlock:0, fontSize:20, fontStyle:'italic', fontWeight: 500, color:'rgba(63, 120, 224, 1)'}} className="text-center" >
     {t("Signup3")} <span className="gradient-text" style={{fontWeight: 700}}>{t("Signup4")}</span> {t("Signup5")}
    </Col>
    </Col>
    <Col style={{background: 'white',borderRadius:15, padding:20, margin: width > 600 ? 30 : 10}}  className="text-center" >
    <Col style={{paddingBlock:20, fontSize:32, fontWeight: 600, color:'rgba(63, 120, 224, 1)'}} className="text-center" >
    {t("Signup0")}
    </Col>
    <Col style={{paddingBlock:10, fontSize:20, fontWeight: 600}} className="text-center" >
    {t("Signup1")}
    </Col>
    <Col style={{paddingBlock:20, fontSize:15, fontWeight: 500}} className="text-center" >
    * {t("Signup2")}
    </Col>
    <Row style={{justifyContent:'center',alignItems:'center'}}>
   
   <Col md>
      <Form.Group className="form-floating mb-3">
      <Form.Control id="Nom" name="Nom" size="lg" type="text" required style={{borderRadius:10, borderColor:'rgba(63, 120, 224, 1)'}} placeholder="Large text" onChange={e =>ChangeNom(e.target.value)} />
      <Form.Label style={{color:'rgba(63, 120, 224, 1)'}} htmlFor="Nom">{t("Signup6")} *</Form.Label>
       </Form.Group>
   </Col>
   <Col md>
         <Form.Group className="form-floating mb-3">
        <Form.Control id="Prenom" name="Prenom" size="lg" type="text" required style={{borderRadius:10, borderColor:'rgba(63, 120, 224, 1)'}} placeholder="Large text" onChange={e =>ChangePrenom(e.target.value)}/>
         <Form.Label style={{color:'rgba(63, 120, 224, 1)'}} htmlFor="Prenom">{t("Signup7")} *</Form.Label>
          </Form.Group>
          </Col>
         </Row>
         <Form.Group className="form-floating mb-3">
         <Form.Control  type="email" 
        id="email"
        size="lg" required style={{borderRadius:10, borderColor:'rgba(63, 120, 224, 1)'}} placeholder="Large text" onChange={e =>ChangeMail(e.target.value)} />
          <Form.Label style={{color:'rgba(63, 120, 224, 1)'}} htmlFor="email">{t("Signup8")} *</Form.Label>
           </Form.Group>    
   
      <InputGroup className="mb-3">
      <InputGroup.Text id="basic-addon1" style={{ borderTopLeftRadius:10, borderBottomLeftRadius:10,borderColor:'rgba(63, 120, 224, 1)', width:'40%',color:'rgba(63, 120, 224, 1)'}}>{t("Signup9")} *</InputGroup.Text>
        <Form.Select style={{height:60, borderTopRightRadius:10, borderBottomRightRadius:10, borderColor:'rgba(63, 120, 224, 1)'}} size="lg" aria-label="Username" placeholder="Username" aria-describedby="basic-addon1" required onChange={e =>ChangeGenre(e.target.value)} value={sexe}>
      {optionSexe.map( (x) => 
      <option key={x.value} value={x.value}>{x.label}</option> )
      }
    </Form.Select>
      </InputGroup>
      <InputGroup className="mb-3">
      <InputGroup.Text id="basic-addon1" style={{ height:60, borderTopLeftRadius:10, borderBottomLeftRadius:10,borderColor:'rgba(63, 120, 224, 1)', width:'40%',color:'rgba(63, 120, 224, 1)'}}>{t("Signup10")} *</InputGroup.Text>
        <Form.Select  value={value}  onChange={e =>changeHandler(e.target.value)} style={{borderTopRightRadius:10, borderBottomRightRadius:10, borderColor:'rgba(63, 120, 224, 1)'}} size="lg" aria-label="Username" placeholder="Username" aria-describedby="basic-addon1">
        {options.map( (x) => 
        <option key={x.value} value={x.value} label={x.label}>{x.label}</option> )
         }
    </Form.Select>
      </InputGroup>
      <InputGroup className="mb-3">
      <InputGroup.Text id="basic-addon1" style={{height:60, borderTopLeftRadius:10, borderBottomLeftRadius:10,borderColor:'rgba(63, 120, 224, 1)', width:  '40%'  ,color:'rgba(63, 120, 224, 1)'}}><Text style={{ color:'rgba(63, 120, 224, 1)', fontSize:'1rem'}}>{t("Signup11")} *</Text></InputGroup.Text>
      <Form.Control value={startDate} size="lg" type="date" required style={{ borderColor:'rgba(63, 120, 224, 1)'}} placeholder="Large text" onChange={e =>ChangeDate(e.target.value)} />
      </InputGroup>
      <Col  className="text-center" >
      <Button variant="outline-light"  onClick={validCommand} style={{borderWidth:0, background: `linear-gradient(to right,  ${disabledcolor} 20%,${disabledcolor1} 90%)`, borderRadius:10, paddingInline:50, paddingBlock:10, marginTop:20, fontSize:24}}>
      {t("Signup12")}  </Button>
    </Col>
    </Col>
    </Row>
    </Col>
  </Container>
   
  
  
  :

  <Container fluid style={{background: `linear-gradient(to right,  ${color1} 60%,${color2} 90%)`, padding:0, margin:0}}>
     <Col style={{background: 'white', padding:0, margin:0}}  className="text-center" >
     <Button variant="outline-light" style={{borderWidth:0}} onClick={() => {
              window.open('https://www.healthsquad.fr/', '_blank')}}>
     <Image src={u75} style={{height: width > 800 ? 100 : 70}}/>
              </Button>
     </Col>
     <Col style={{marginTop:40}} lg={{ span: 4, offset: 4 }} xs={{ span: 6, offset: 3 }}>
     <ProgressBar animated now={100} style={{backgroundColor:'white'}}/>
     </Col>
     <Col md={{ span: 10, offset:1  }} lg={{ span: 10, offset: 1 }} style={{marginTop:20,background: 'rgba(255, 255, 255, 0.35)',borderRadius:15, padding:20,marginBlock:40}}>
    <Row style={{justifyContent:'center',alignItems:'center'}}>
     <Col   className="text-center d-none d-xl-block"  >
     <Row>
      <Col className="text-start" >
        <Image src={u76}  style={{ background:"white", borderRadius:50}}/>
      </Col>
     </Row>
     <Row>
      <Col className="text-end" >
        <Image src={u77}  style={{ background:"white", borderRadius:50}}/>
      </Col>
     </Row>
     <Row>
      <Col className="text-center" style={{marginBlock:20}}>
        <Image src={u78}  style={{ background:"white", borderRadius:150}}/>
      </Col>
     </Row>
     <Row>
      <Col className="text-start" style={{marginLeft:60}}>
        <Image src={u79}  style={{ background:"white", borderRadius:50}}/>
      </Col>
     </Row>
     <Row>
      <Col className="text-end" style={{marginRight:20}}>
        <Image src={u80}  style={{ background:"white", borderRadius:50}}/>
      </Col>
     </Row>
     <Row>
      <Col className="text-center" style={{marginBlock:20}}>
        <Image src={u81}  style={{ background:"white", borderRadius:100}}/>
      </Col>
     </Row>
     <Row>
      <Col className="text-start" style={{marginLeft:40}}>
        <Image src={u82}  style={{ background:"white", borderRadius:50}}/>
      </Col>
     </Row>
     <Row>
      <Col className="text-end" style={{marginRight:100}}>
        <Image src={u83}  style={{ background:"white", borderRadius:80}}/>
      </Col>
     </Row>
    </Col>
    <Col style={{background: 'white',borderRadius:15, padding:20, margin: width > 600 ? 30 : 10}}  className="text-center" >
    <Col style={{paddingBlock:20, fontSize:32, fontWeight: 600, color:'rgba(63, 120, 224, 1)'}} className="text-center" >
    {t("Signup13")}...
    </Col>
    <Col style={{paddingBlock:10, fontSize:20, fontWeight: 600}} className="text-center" >
    {t("Signup14")}
    </Col>
    <Col style={{paddingBlock:20, fontSize:15, fontWeight: 500}} className="text-center" >
    *{t("Signup2")}
    </Col>
    <Form.Group className="form-floating mb-3">
         <Form.Control  type="tel" id="phone" name="phone" size="lg" required style={{borderRadius:10, borderColor:'rgba(63, 120, 224, 1)'}} placeholder="Large text" value={phone} onChange={e =>ChangePhone(e.target.value)} />
          <Form.Label style={{color:'rgba(63, 120, 224, 1)'}} htmlFor="Nom">{t("Signup15")} *</Form.Label>
           </Form.Group>   
           <InputGroup className="mb-3">
      <InputGroup.Text id="basic-addon1" style={{height:60, borderTopLeftRadius:10, borderBottomLeftRadius:10,borderColor:'rgba(63, 120, 224, 1)', width:'40%',color:'rgba(63, 120, 224, 1)'}}>{t("Signup16")} *</InputGroup.Text>
        <Form.Select required onChange={e =>ChangeProfession(e.target.value)} value={Profession} style={{borderTopRightRadius:10, borderBottomRightRadius:10, borderColor:'rgba(63, 120, 224, 1)'}} size="lg" aria-label="Username" placeholder="Profession *" aria-describedby="basic-addon1">
      {optionMetier.map( (x) => 
      <option key={x.value} value={x.value}>{x.label}</option> )
      }
    </Form.Select>
      </InputGroup> 
           <Form.Group className="form-floating mb-3">
         <Form.Control  type="text" id="metier" name="metier" size="lg" required style={{borderRadius:10, borderColor:'rgba(63, 120, 224, 1)'}} placeholder="Large text" value={Metier} onChange={e =>ChangeMetier(e.target.value)} />
          <Form.Label style={{color:'rgba(63, 120, 224, 1)'}} htmlFor="Nom">{t("Signup28")} *</Form.Label>
           </Form.Group>    
           {  Profession === 'Professionnel de santé' ? <>
           <Form.Group className="form-floating mb-3">
         <Form.Control  type="text" id="special" name="special" size="lg" required style={{borderRadius:10, borderColor:'rgba(63, 120, 224, 1)'}} placeholder="Large text" value={Special} onChange={e =>ChangeSpecial(e.target.value)}/>
          <Form.Label style={{color:'rgba(63, 120, 224, 1)'}} htmlFor="Nom">{t("Signup29")} *</Form.Label>
           </Form.Group>   
                         
          <Col  style={{color:'white', fontWeight:500,fontSize:18, paddingBottom:10}}>{t("Signup29")} *</Col>
          <Form>
      <Form.Check
        className="text-start"
        checked={check1}
        onChange={()=>{Changecheck1(!check1)}}
        style={{marginBottom:20}}
        type="checkbox"
        id="custom-check"
        label={<Col className="text-start" style={{fontSize:15,marginInline:20}}>
                                {t("Signup31")}
                                </Col>}
      />
     
     <Form.Check
        className="text-start"
        checked={check2}
        onChange={()=>{Changecheck2(!check2)}}
        style={{marginBottom:20}}
        type="checkbox"
        id="custom-check2"
        label={  <Col className="text-start" style={{fontSize:15,marginInline:20}}>
                                {t("Signup32")}
                                </Col>}
      />
   </Form>
                          </> : undefined}
           <Col style={{paddingBlock:20, fontSize:16, fontWeight: 600}} className="text-start" >
           {t("Signup31")} :
    </Col>
    <Row style={{justifyContent:'center'}}>
    <Col md>
       <Form.Group className="form-floating mb-3">
       <Form.Control size="lg" type="text" id="numrue" name="numrue" required style={{borderRadius:10, borderColor:'rgba(63, 120, 224, 1)'}} placeholder="Large text" value={numRue} onChange={e =>ChangenumRue(e.target.value)} />
       <Form.Label style={{color:'rgba(63, 120, 224, 1)'}} htmlFor="Nom">{t("Signup34")} *</Form.Label>
        </Form.Group>
    </Col>
    <Col md>
          <Form.Group className="form-floating mb-3">
         <Form.Control size="lg" type="street"  id="street"  name="street" required style={{borderRadius:10, borderColor:'rgba(63, 120, 224, 1)'}} placeholder="Large text" value={rue} onChange={e =>ChangeRue(e.target.value)} />
          <Form.Label style={{color:'rgba(63, 120, 224, 1)'}} htmlFor="street">{t("Signup35")} *</Form.Label>
           </Form.Group></Col>
          </Row>

          <Form.Group className="form-floating mb-3">
         <Form.Control  type="text" required style={{borderRadius:10, borderColor:'rgba(63, 120, 224, 1)'}} placeholder="Large text" value={compAd} onChange={e =>ChangeCompAd(e.target.value)} />
          <Form.Label style={{color:'rgba(63, 120, 224, 1)'}} htmlFor="Nom">{t("Signup36")}</Form.Label>
           </Form.Group>    
    <Row style={{justifyContent:'center'}}>
    <Col md>
       <Form.Group className="form-floating mb-3">
       <Form.Control size="lg" type="zip"  id="inputZip"  name="inputZip"  required style={{borderRadius:10, borderColor:'rgba(63, 120, 224, 1)'}} placeholder="Large text" value={codepostale}  onChange={e =>ChangeCodepostale(e.target.value)} />
       <Form.Label style={{color:'rgba(63, 120, 224, 1)'}} htmlFor="Nom">{t("Signup37")} *</Form.Label>
        </Form.Group>
    </Col>
    <Col md>
          <Form.Group className="form-floating mb-3">
         <Form.Control type="city"  size="lg" id="inputCity" name="inputCity"   required style={{borderRadius:10, borderColor:'rgba(63, 120, 224, 1)'}} placeholder="Large text" onClick={()=>setModalVisible3(true)} onChange={e =>ChangeVille(e.target.value)} />
         <ListGroup>
                                  {modalVisible3 && dataVille.map((item, index) => (
                                  <ListGroup.Item action variant="" key={index} onClick={()=>{ChangeVille(item.label); setModalVisible3(false)}}>
                                  {item.label}
                                  </ListGroup.Item>
                                ))} 
                                </ListGroup>
          <Form.Label style={{color:'rgba(63, 120, 224, 1)'}} htmlFor="inputCity">{t("Signup38")} *</Form.Label>
           </Form.Group></Col>
          </Row>
     
      <InputGroup className="mb-3">
      <InputGroup.Text id="basic-addon1" style={{borderTopLeftRadius:10, borderBottomLeftRadius:10,borderColor:'rgba(63, 120, 224, 1)', width:'40%',color:'rgba(63, 120, 224, 1)'}}>{t("Signup38")} *</InputGroup.Text>
        <Form.Select  onChange={e =>changeHandler2(e.target.value)} value={value2} style={{height:60,borderTopRightRadius:10, borderBottomRightRadius:10, borderColor:'rgba(63, 120, 224, 1)'}} size="lg" aria-label="Username" placeholder="Username" aria-describedby="basic-addon1">
        {options.map( (x) => 
        <option key={x.value} value={x.value} label={x.label}>{x.label}</option> )
         }
    </Form.Select>
    
      </InputGroup>
      <Form>
      <Form.Check
        className="text-start"
        defaultChecked={checked2}
        value={checked2}
        onChange={handleChange2}
        style={{marginBottom:20}}
        type="checkbox"
        id="custom-checkbox2"
        label={<Col className="text-start" style={{fontSize:15,marginInline:20}}>
                                {t("Signup40")}
                                </Col>}
      />
     
     <Form.Check
        className="text-start"
        value={checked}
        onChange={handleChange}
        
        style={{marginBottom:20}}
        type="checkbox"
        id="custom-checkbox"
        label={  <Col className="text-start" style={{fontSize:15,marginInline:20}}>
                                {t("Signup41")} <a href="mailto:contact@healthsquad.fr">Health Squad</a>. {t("Signup42")} <a href='https://www.healthsquad.fr/protection-des-donnees-personnelles' target="_blank">{t("Signup43")}</a> {t("Signup44")}
                                </Col>}
      />
   </Form>
     
      <Col  className="text-center" >
      <Button variant="outline-light" onClick={()=>validCommand1()} style={{borderWidth:0, background: `linear-gradient(to right,  ${disabledcolor2} 20%,${disabledcolor3} 90%)`, borderRadius:10, paddingInline:50, paddingBlock:10, marginTop:20, fontSize:24}}>
      {t("Signup45")}  </Button>
    </Col>
    </Col>
    </Row>
    </Col>
  </Container> }
  <Modal3 show={show2} Quit={() => {setShow2(false); window.scrollTo(0, 0)}} validCommand3={()=>validCommand3()}/>
  </>
  );
}




export default Inscription