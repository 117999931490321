import React, {useState, useEffect} from 'react';
import './App.css';
import {Link, NavLink} from "react-router-dom";
import { Container, Row, Col, Image, Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import doodle1 from './images/doodle1.svg';
import doodle2 from './images/doodle2.svg';
import Diabeloop from './images/Diabeloop_NB.png';
import Mybrain from './images/Mybrain_NB.png';
import UseConcept from './images/UseConcept_NB.png';
import Teknimed from './images/Teknimed_NB.png';
import Eclypia from './images/Eclypia_NB.png';
import Valotec from './images/Valotec_NB.png';
import Symatese from './images/Symatese_NB.png';
import Getinge from './images/Getinge_NB.png';
import docteur from './images/docteur.png';
import capsules from './images/capsules.png';
import analytique from './images/analytique.png';
import conception from './images/conception.png';
import convention from './images/convention.png';
import test1 from './images/Test1.png'; 
import approuve from './images/approuve.png';
import suivi from './images/suivi-post.png';
import electrocardiogramme from './images/electrocardiogramme.png';
import electrocardiogramme2 from './images/electrocardiogramme2.png';
import verification from './images/verification.png';
import couverture from './images/couverture.avif';
import couverture1 from './images/couverture1.avif';
import "react-datepicker/dist/react-datepicker.css";
import {useWindowDimensions} from 'react-native';
import {Translations} from '../src/constants/i18n';

const Home = ({translation}) => {

const [DataEtude, setDataEtude] = useState([]);
const { height, width } = useWindowDimensions();
const [isFixed, setIsFixed] = useState(false);
const [isScrollTop, setScrollTop] = useState(false);
console.log('homeT',translation)
const [step, setStep] = useState(1);
const URL = 'https://www.healthsquad.fr/request/'

const t = (value) => {
  return Translations[0][translation][value];
 }

useEffect(()=>{
 
   fetch(`${URL}json_get_react_blog.php`)
    .then((response) => response.json())
    .then((response) => { 
      const newt =[]
      for (var i = 0; i < response.length; i++) {
        if(response[i].ID >= response.length - 1){
           newt.push(response[i])
        }
      }
      setDataEtude(newt)
  console.log(response)
    })
    .catch((error) => alert(error))
  
  const handleScroll = () => {
    const scrollTop =  document.documentElement.scrollTop;
    console.log('scrollTop',scrollTop)
    if (scrollTop > 3120  && scrollTop < 5000) {
      setIsFixed(true);
      setScrollTop(scrollTop)
      console.log('Fixed',true)
    } else {
      setIsFixed(false);
      setScrollTop(scrollTop)
      console.log('Fixed',false)
    }
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
 
 
  }, [])


  return (
    <>
    <div id="top"></div>
    <Container  style={{ paddingBottom:0, paddingTop:100}} fluid>
      <Row style={{justifyContent:'center',alignItems:'center', background: '#f0f0f8'}}>
        
            <Col xs={{ order: 'last' }} lg={{ order: 'first', span:6 }} style={{paddingInline:30, paddingBlock:30}}>
            
                  <Col lg={{span: 10, offset: 1 }} xxl={{span: 7, offset: 2 }} className="text-lg-start text-center" style={{backgroundColor:''}}>
                  <Col  className="text-start" style={{backgroundColor:''}}>
                   <Image src={doodle1} style={{ marginLeft: width < 768 ? 0 : -50,height:50}} alt='Recrutement pro de santé loi anti-cadeaux' />
                   </Col>
                    <h1  style={{color:'black', fontSize:56,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}>{t("home1")} <span className="gradient-text2">{t("home2")}</span> {t("home3")}</h1>
                    <h3  style={{color:'#4f6382', fontSize:25,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.4}}>{t("home4")}</h3>
                 
                 
                 <Row style={{justifyContent:'center',alignItems:'center'}}>
      
        <Col style={{marginTop:0}}>
       
        <Button variant="outline-light" href="/devis"  style={{borderWidth:0, background: `linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`, borderRadius:10, paddingInline:45, paddingBlock:20, fontSize:20,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif'}}>{t("btn2")}</Button>
       
              </Col>  
      </Row>
     
                  </Col>
                  <Col  className="text-end" style={{backgroundColor:''}}>
                   <Image src={doodle2} style={{ height:50}} alt='Recrutement pro de santé loi anti-cadeaux'/>
                   </Col>
                   
            </Col>
            
            <Col xs={{ order: 'first' }} lg={{ order: 'last' , span:6}}  >

            {/* <Image src={couverture} fluid /> */}
            <div style={{ 
      backgroundImage: `url(${width < 1000 ? couverture1 : couverture})`, 
      backgroundPosition: 'center', 
      backgroundSize: 'cover', 
      width: 'auto', 
      height: width < 1000 ? '60vw' :'40vw' 
    }}
    alt='Recrutement pro de santé loi anti-cadeaux'
    >
     
    </div>
            </Col>
      </Row>
      <Row style={{justifyContent:'center',alignItems:'center', background: '#f5f7fa', paddingBlock:30}}>
           <Col className="text-center">
              <Image src={Diabeloop} style={{ height:120}} alt='Diabeloop'/>
            </Col>  
            <Col className="text-center">
              <Image src={Mybrain} style={{ height:90}} alt='Mybrain'/>
            </Col>  
            <Col className="text-center">
              <Image src={UseConcept} style={{ height:60}} alt='UseConcept'/>
            </Col>  
            <Col className="text-center">
              <Image src={Teknimed} style={{ height:50}} alt='Teknimed'/>
            </Col>  
            <Col className="text-center">
              <Image src={Eclypia} style={{ height:70}} alt='Eclypia'/>
            </Col>  
            <Col className="text-center">
              <Image src={Valotec} style={{ height:70}} alt='Valotec'/>
            </Col>  
            <Col className="text-center">
              <Image src={Symatese} style={{ height:70}} alt='Symatese'/>
            </Col>  
            <Col className="text-center">
              <Image src={Getinge} style={{ height:30}} alt='Getinge'/>
            </Col>  
      </Row>



     <Row style={{ background: '#fcfcfd', paddingBottom: 80}}>
      <Col   lg={{span: 4, offset: 4 }} style={{  paddingBottom: 60}}>
        <Col className="text-center" style={{margin:60, marginTop:100 ,fontSize: 20,color: '#4d7abe',paddingInline:0, fontWeight:'bold', fontFamily:'Space Grotesk, sans-serif'}}>SERVICES</Col>
        <Col className="text-center"  style={{marginTop:20,fontSize: 44,paddingInline:0, fontWeight:'600', fontFamily:'Space Grotesk, sans-serif'}}>{t("service0")} <span className="gradient-text2">{t("service0-1")} </span>{t("service0-2")}</Col>
      </Col>  
      <Col  md={{ span: 10, offset: 1 }}>
       { width > 1000 && <>
        <Row  className="justify-content-md-center"  style={{ paddingBlock:10}}>
           <Col className="joinBtn" onClick={()=> setStep(1)} lg="3" style={{cursor:'pointer', background: step === 1 ? '#ffffff': undefined, boxShadow: step === 1 ? '0 5px 35px rgba(30, 34, 40, .2)': undefined, padding:40, borderRadius:10, marginInline:10}}>
            <Row>
              <Col xs={3} style={{fontSize:50, fontWeight: 600, fontFamily:'Space Grotesk, sans-serif'}} className="gradient-text3 text-center">
              01
              </Col>  
              <Col xs={9} style={{color: '#60697b', fontSize:17, fontWeight: 600}}  className="my-auto">
              {t("service1-1")}
              </Col> 
              </Row>
            </Col>  
            <Col className="joinBtn" onClick={()=> setStep(2)} lg="3" style={{cursor:'pointer', background: step === 2 ? '#ffffff': undefined, boxShadow: step === 2 ? '0 5px 35px rgba(30, 34, 40, .2)': undefined, padding:40, borderRadius:10, marginInline:10}}>
            <Row>
              <Col xs={3} style={{fontSize:50, fontWeight: 600, fontFamily:'Space Grotesk, sans-serif'}} className="gradient-text3 text-center">
              02
              </Col>  
              <Col xs={9} style={{color: '#60697b', fontSize:17, fontWeight: 600}} className="my-auto">
              {t("service2-1")}
              </Col> 
              </Row>
            </Col>  
            <Col className="joinBtn" onClick={()=> setStep(3)} lg="3" style={{cursor:'pointer', background: step === 3 ? '#ffffff': undefined, boxShadow: step === 3 ? '0 5px 35px rgba(30, 34, 40, .2)': undefined, padding:40, borderRadius:10, marginInline:10}}>
            <Row>
              <Col xs={3} style={{fontSize:50, fontWeight: 600, fontFamily:'Space Grotesk, sans-serif'}} className="gradient-text3 text-center">
              03
              </Col>  
              <Col xs={9}  style={{color: '#60697b', fontSize:17, fontWeight: 600}} className="my-auto">
              {t("service3-1")}
              </Col> 
              </Row>
            </Col>  
          
            </Row></>}
            
            {(step === 1 || width < 1000) && <Row  style={{justifyContent:'center',alignItems:'center', padding:40}}>
        <Col  xs={{ order: 'first' }} lg={{ order: 'first', span:5 }}>

        <Image src={test1} style={{borderRadius:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>
        </Col>
        <Col  xs={{ order: 'last' }} lg={{ order: 'last' , span:5}} style={{paddingInline:0, paddingBlock:30}}>
        
              <Col xxl={{span: 10, offset: 1 }} className="text-lg-start text-center" style={{backgroundColor:''}}>
              <Col  style={{color:'black', fontSize:26,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}>{t("service1-2")}</Col>
                <Col className="text-start" style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.7}}>{t("service1-3")}</Col>
                <Col className="text-start" style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("service1-4")}</Col>
                <Col className="text-start" style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("service1-5")}</Col>
                <Col className="text-start" style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("service1-6")}</Col>
                <Col className="text-start" style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("service1-7")}</Col>
             
             <Row style={{justifyContent:'center',alignItems:'center'}}>
  
    <Col style={{marginTop:0}}>
    <div className="d-grid gap-2">
    <Button variant="outline-light" style={{borderWidth:0, background: `linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`, borderRadius:10, paddingInline:45, paddingBlock:20, fontSize:20,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif'}}><NavLink style={{paddingBlock:10, paddingRight:20, color:'white', textDecorationLine:'inherit'}}  to='/services/recrutement'>{t("btn3")}</NavLink></Button>
    </div>
          </Col>  
  </Row>
 
              </Col>
              
               
        </Col>
        
        
  </Row>}
     {(step === 2 || width < 1000) && <Row  style={{justifyContent:'center',alignItems:'center', padding:40}}>
        <Col  xs={{ order: 'last' }} lg={{ order: 'first', span:5 }} style={{paddingInline:0, paddingBlock:30}}>
              <Col xxl={{span: 10, offset: 1 }} className="text-lg-start text-center" style={{backgroundColor:''}}>
              <Col className="text-start" style={{color:'black', fontSize:26,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}>{t("service2-2")}</Col>
                <Col className="text-start" style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.7}}>{t("service2-3")}</Col>
                <Col className="text-start" style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("service2-4")}</Col>
                <Col className="text-start" style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("service2-5")}</Col>
                <Col className="text-start" style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("service2-6")}</Col>
             <Row style={{justifyContent:'center',alignItems:'center'}}>
    <Col style={{marginTop:0}}>
    <div className="d-grid gap-2">
    <Button variant="outline-light" style={{borderWidth:0, background: `linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`, borderRadius:10, paddingInline:45, paddingBlock:20, fontSize:20,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif'}}><NavLink style={{paddingBlock:10, paddingRight:20, color:'white', textDecorationLine:'inherit'}}  to='/services/Loi-anti-cadeau'>{t("btn3")}</NavLink></Button>
    </div>
          </Col>  
  </Row>
 
              </Col>
              
               
        </Col>
        
        <Col  xs={{ order: 'first' }} lg={{ order: 'last' , span:5}}  >

        <Image src={convention} style={{borderRadius:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>
        </Col>
  </Row>}

  {(step === 3 || width < 1000) && <Row  style={{justifyContent:'center',alignItems:'center', padding:40}}>
        <Col  xs={{ order: 'first' }} lg={{ order: 'first', span:5 }}  >
        <Image src={suivi} style={{borderRadius:10}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>
        </Col>
        <Col  xs={{ order: 'last' }} lg={{ order: 'last' , span:5}} style={{paddingInline:0, paddingBlock:30}}>
              <Col xxl={{span: 10, offset: 1 }} className="text-lg-start text-center" style={{backgroundColor:''}}>
                <Col className="text-start" style={{color:'black', fontSize:26,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}>{t("service3-2")}</Col>
                <Col className="text-start" style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.7}}>{t("service3-3")}</Col>
                <Col className="text-start" style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("service3-4")}</Col>
                <Col className="text-start" style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("service3-5")}</Col>
                <Col className="text-start" style={{color:'#4f6382', fontSize:17,fontWeight: '400', fontFamily:'Space Grotesk, sans-serif' ,marginBottom:20,lineHeight:1.2}}><Image src={approuve} style={{borderRadius:10, height:22, width:22, marginRight:5}} fluid alt='Recrutement pro de santé loi anti-cadeaux'/>{t("service3-6")}</Col>
             <Row style={{justifyContent:'center',alignItems:'center'}}>
    <Col style={{marginTop:10}}>
    <div className="d-grid gap-2">
    <Button variant="outline-light" style={{borderWidth:0, background: `linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`, borderRadius:10, paddingInline:45, paddingBlock:20, fontSize:20,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif'}}><NavLink style={{paddingBlock:10, paddingRight:20, color:'white', textDecorationLine:'inherit'}}  to='/services/suivi-post-production'>{t("btn3")}</NavLink></Button>
    </div>
          </Col>  
  </Row>
 
              </Col>
              
               
        </Col>
        
        
  </Row>}
  </Col>
        </Row>  

 <Row   xs={2} md={4} lg={6} style={{justifyContent:'center',alignItems:'center', background: '#f5f7fa', paddingBlock:30}}>
           <Col>
              <Col style={{color: '#60697b', fontSize:40, fontWeight: 600}} className="text-center">
              + 5000
              </Col>  
              <Col style={{color: '#60697b', fontSize:17, fontWeight: 400}} className="text-center">
              {t("numbers1")}
              </Col> 
            </Col>  
            <Col>
              <Col style={{color: '#60697b', fontSize:40, fontWeight: 600}} className="text-center">
              70
              </Col>  
              <Col style={{color: '#60697b', fontSize:17, fontWeight: 400}} className="text-center">
              {t("numbers2")}
              </Col> 
            </Col>  
            <Col>
              <Col style={{color: '#60697b', fontSize:40, fontWeight: 600}} className="text-center">
              750
              </Col>  
              <Col style={{color: '#60697b', fontSize:17, fontWeight: 400}} className="text-center">
              {t("numbers3")}
              </Col> 
            </Col>  
            <Col>
              <Col style={{color: '#60697b', fontSize:40, fontWeight: 600}} className="text-center">
              225
              </Col>  
              <Col style={{color: '#60697b', fontSize:17, fontWeight: 400}} className="text-center">
              {t("numbers4")}
              </Col> 
            </Col>  
      </Row>
      <Row style={{background: '#f0f0f8',}}>
      <Col style={{  paddingBlock:80}}>
      
      <Col   lg={{span: 6, offset: 3 }} style={{alignSelf:'center'}}>
      <Col className="text-center" style={{marginTop:20, fontSize: 20,color: '#4d7abe',paddingInline:0, fontWeight:'bold', fontFamily:'Space Grotesk, sans-serif'}}>{t("ref0-0")}</Col>
      <Col className="text-center"  style={{marginTop:20,fontSize: 44,paddingInline:0, fontWeight:'600', fontFamily:'Space Grotesk, sans-serif'}}>{t("ref0")} <span className="gradient-text2">{t("ref0-1")}</span> {t("ref0-2")}</Col>
      </Col>
      <Row  xs={1} md={4}  style={{justifyContent:'center', paddingBlock:50}}>

           <Col >
              <Card className="p-4">
                <Card.Body>
                  <Card.Text className="mb-5 text-muted" style={{fontSize:17}} >{t("ref1")}</Card.Text>
                  <Card.Title >Capucine CHAMBARD</Card.Title>
                  <Card.Subtitle className="text-muted">Diabeloop</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>  
            <Col>
            <Card className="p-4">
                <Card.Body>
                <Card.Text className="mb-5 text-muted" style={{fontSize:17}}>{t("ref2")}</Card.Text>
                   <Card.Title>Clémence DE BELLIS</Card.Title>
                  <Card.Subtitle className="text-muted">UseConcept</Card.Subtitle>
                </Card.Body>
              </Card>
              </Col> 
            <Col>
            <Card className="p-4">
                <Card.Body>
                <Card.Text className="mb-5 text-muted" style={{fontSize:17}}>{t("ref3")}</Card.Text>
                   <Card.Title>Fabien MARCQ</Card.Title>
                  <Card.Subtitle className="text-muted">Teknimed</Card.Subtitle>
                </Card.Body>
              </Card>
            </Col>  
      </Row>
      
      </Col>  
      </Row>
       <Row  md={1} style={{justifyContent:'center',alignItems: isScrollTop > 5000 ? 'end' : 'start',  background: '#fcfcfd', paddingBlock:90}}>
        <Col xs={{ order: 'last' }} md={{ order: 'first' }} lg="6" style={{paddingInline:30, paddingBlock:0}}>
        <Col className="m-auto" style={{ position: isFixed && width > 1000 ? 'fixed' : 'static', top:  isFixed && width > 1000 ? 300 : undefined, width: isFixed && width > 1000 ? width /2.13 : undefined}}>
        <Col lg={{span: 8, offset: 2 }}  className="text-center" style={{backgroundColor:''}}>
        <Col className="text-center" style={{marginTop:20, fontSize: 20,color: '#4d7abe',paddingInline:0, fontWeight:'bold', fontFamily:'Space Grotesk, sans-serif'}}>{t("exemple0")}</Col>
      <Col className="text-center"  style={{marginTop:20,fontSize: 44,paddingInline:0, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}>{t("exemple00")} <span className="gradient-text">{t("exemple01")}</span></Col>
      <Col className="text-center" style={{marginTop:20,fontSize: 17,color: '#60697b',paddingInline:0, fontWeight:'400', fontFamily:'Space Grotesk, sans-serif'}}>{t("exemple02")}</Col>
      <Button  variant="outline-light"  style={{marginTop:20,borderWidth:0, background: `linear-gradient(to right,  rgba(188, 78, 156, 1) 20%, rgba(63, 120, 224, 1) 90%)`, borderRadius:10, paddingInline:45, paddingBlock:20, fontSize:20,fontWeight: '600', fontFamily:'Space Grotesk, sans-serif'}}>{t("btn1")}</Button>
      </Col>  
      </Col>
        </Col>  
        <Col xs={{ order: 'last' }} md={{ order: 'first' }} lg="6" style={{paddingInline:0, paddingBlock:0}}>
         {/*  <div className="outer-container">*/}
      {/* Conteneur extérieur avec défilement vertical 
      <div className="inner-container">*/}
        {/* Conteneur intérieur avec défilement vertical
        <div className="scrollable-y"  style={{paddingInline:30, paddingBlock:90}}> */}
          {/* Contenu vertical */}
          <Col lg="12" xl="8" style={{ background: '#ffffff', boxShadow: '0 5px 35px rgba(30, 34, 40, .07)', padding:40, borderRadius:10, margin:20}}>
          <Row>
          <Col xs="4" lg="3" className="m-auto">
              <Image src={analytique} style={{ height:100}} alt='Recrutement pro de santé loi anti-cadeaux'/>
              </Col>
                <Col xs="9" className="m-auto">
                  <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}>{t("exemple1")}</Col>
                  <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'300', fontFamily:'Space Grotesk, sans-serif'}}>{t("exemple11")}</Col>
                </Col>  
              </Row>
            </Col>  
            <Col lg="12" xl="8" style={{ background: '#ffffff', boxShadow: '0 5px 35px rgba(30, 34, 40, .07)', padding:40, borderRadius:10, margin:20}}>
          <Row>
          <Col xs="4" lg="3" className="m-auto">
              <Image src={conception} style={{ height:100}} alt='Recrutement pro de santé loi anti-cadeaux'/>
              </Col>
                <Col xs="9" className="m-auto">
                  <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}>{t("exemple2")}</Col>
                  <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'300', fontFamily:'Space Grotesk, sans-serif'}}>{t("exemple22")}</Col>
                </Col>  
              </Row>
            </Col>  
            <Col lg="12" xl="8" style={{ background: '#ffffff', boxShadow: '0 5px 35px rgba(30, 34, 40, .07)', padding:40, borderRadius:10, margin:20}}>
          <Row>
          <Col xs="4" lg="3" className="m-auto">
              <Image src={verification} style={{ height:100}} alt='Recrutement pro de santé loi anti-cadeaux'/>
              </Col>
              <Col xs="9" className="m-auto">
                  <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}>{t("exemple3")}</Col>
                  <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'300', fontFamily:'Space Grotesk, sans-serif'}}>{t("exemple33")}</Col>
                </Col>  
              </Row>
            </Col>  
            <Col lg="12" xl="8" style={{ background: '#ffffff', boxShadow: '0 5px 35px rgba(30, 34, 40, .07)', padding:40, borderRadius:10, margin:20}}>
          <Row>
          <Col xs="4" lg="3" className="m-auto">
              <Image src={electrocardiogramme} style={{ height:100}} alt='Recrutement pro de santé loi anti-cadeaux'/>
              </Col>
              <Col xs="9" className="m-auto">
                  <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}>{t("exemple4")}</Col>
                  <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'300', fontFamily:'Space Grotesk, sans-serif'}}>{t("exemple44")}</Col>
                </Col>  
              </Row>
            </Col>  
            <Col lg="12" xl="8" style={{ background: '#ffffff', boxShadow: '0 5px 35px rgba(30, 34, 40, .07)', padding:40, borderRadius:10, margin:20}}>
          <Row>
          <Col xs="4" lg="3" className="m-auto">
              <Image src={docteur} style={{ height:100}} alt='Recrutement pro de santé loi anti-cadeaux'/>
              </Col>
              <Col xs="9" className="m-auto">
                  <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}>{t("exemple5")}</Col>
                  <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'300', fontFamily:'Space Grotesk, sans-serif'}}>{t("exemple55")}</Col>
                </Col>  
              </Row>
            </Col>  
            <Col lg="12" xl="8" style={{ background: '#ffffff', boxShadow: '0 5px 35px rgba(30, 34, 40, .07)', padding:40, borderRadius:10, margin:20}}>
          <Row>
          <Col xs="4" lg="3" className="m-auto">
              <Image src={electrocardiogramme2} style={{ height:100}} alt='Recrutement pro de santé loi anti-cadeaux'/>
              </Col>
              <Col xs="9" className="m-auto">
                  <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}>{t("exemple6")}</Col>
                  <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'300', fontFamily:'Space Grotesk, sans-serif'}}>{t("exemple66")}</Col>
                </Col>  
              </Row>
            </Col>  
            <Col lg="12" xl="8" style={{ background: '#ffffff', boxShadow: '0 5px 35px rgba(30, 34, 40, .07)', padding:40, borderRadius:10, margin:20}}>
          <Row>
          <Col xs="4" lg="3" className="m-auto">
              <Image src={capsules} style={{ height:100}} alt='Recrutement pro de santé loi anti-cadeaux'/>
              </Col>
              <Col xs="9" className="m-auto">
                  <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}>{t("exemple7")}</Col>
                  <Col className="text-center text-lg-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'300', fontFamily:'Space Grotesk, sans-serif'}}>{t("exemple77")}</Col>
                </Col>  
              </Row>
            </Col>  
           
          
       
        </Col>  
        </Row>   

        <Row style={{ backgroundColor:'#f5f7fa'}}>
        <Col style={{paddingTop:100, paddingBottom:80}} >  

<Col className="text-center" style={{marginTop:20, fontSize: 20,color: '#4d7abe',paddingInline:0, fontWeight:'bold', fontFamily:'Space Grotesk, sans-serif'}}>BLOG</Col>
<Col className="text-center" style={{marginTop:20,fontSize: 17,color: '#60697b',paddingInline:0, fontWeight:'400', fontFamily:'Space Grotesk, sans-serif'}}><NavLink style={{paddingBlock:10, paddingRight:20}}  to='/blog'>{t("blogHome0")}</NavLink></Col>
<Col className="text-center"  style={{marginTop:20,fontSize: 44,paddingInline:0, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}>{t("blogHome1")} <span className="gradient-text">{t("blogHome2")}</span></Col>


<Row xs={1} md={2} style={{marginTop:80, padding:20}}>
{ DataEtude && DataEtude.map((item,index) => (<div key={index}>
<Col  style={{ padding:20}}>
<Link to={"/post/"+ item.ID + "/"+ (item.Titre.replaceAll(" ", "-")).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase('tr')}>
<Col >
<div style={{ height: width < 768 ? width/2 : width/3.4,
width: '100%',
backgroundImage: `url(${item.Img})`,
backgroundSize: '100% 100%' ,
borderRadius:10,
objectFit:'cover',

}}
alt='Recrutement pro de santé loi anti-cadeaux'
>

</div>
</Col></Link>
<Col className="text-start" style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}>{item.Titre}</Col>
<Link to={"/post/"+ item.ID + "/"+ (item.Titre.replaceAll(" ", "-")).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLocaleLowerCase('tr')} style={{fontSize: width > 1440 ? 18:16,marginTop:10, fontWeight:'500', fontFamily:'Space Grotesk, sans-serif'}}>Consulter l'article</Link>
</Col></div>
))} 
</Row>

</Col>
 </Row>  
    </Container>
  
      
    </>

  );
}

export default Home